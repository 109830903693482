import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import QueryString from 'qs';
import { REACT_APP_LOCATION_API, REACT_APP_SSO_API } from 'utils/env';
// import { getStorageData, STORAGE_KEY } from 'utils/storage';

axios.defaults.timeout = 15000;
axios.defaults.timeoutErrorMessage = 'Mất kết nối. Vui lòng thử lại sau';
axios.defaults.paramsSerializer = (params): string => QueryString.stringify(params, { indices: false });

const configure = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // const token = getStorageData<string>(STORAGE_KEY.ACCESS_TOKEN);

  const targetConfig: AxiosRequestConfig = {
    headers: {
      // Authorization: `Bearer ${token}`,
      accept: '*/*',
      // "Cookie": "JSESSIONID=B00FB70A9DA34D1D9BFC5C936C15AB71"
    },
    // withCredentials: true,
  };
  return merge(targetConfig, config);
};

/** Request API */
export const apiApp = axios.create({
  baseURL: REACT_APP_SSO_API,
});

apiApp.interceptors.request.use(
  (config) => Promise.resolve(configure(config)),
  (error) => Promise.reject(error)
);

/** Request API */

const configureLocation = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const targetConfig: AxiosRequestConfig = {
    headers: {
      accept: '*/*',
    },
  };
  return merge(targetConfig, config);
};

export const apiAppLocation = axios.create({
  baseURL: REACT_APP_LOCATION_API,
});

apiAppLocation.interceptors.request.use(
  (config) => Promise.resolve(configureLocation(config)),
  (error) => Promise.reject(error)
);