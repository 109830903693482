// import { CContainer } from '@coreui/react';
// import Loading from 'components/Loading';
import routesMap from 'layouts/routesMap';
import React from 'react';
import { Navigate, } from 'react-router-dom';
// import { isLogin } from 'utils/common';
// import AppHeader from './AppHeader';
// import AppSidebar from './AppSidebar';

const PrivateLayout: React.FC = () => {
  // const isLogged = useMemo(() => {
  //   return isLogin()
  // }, []);

  // useEffect(() => {
  //   isLoginCheck()
  // },[])

  return <Navigate to={routesMap.LOGIN} replace />
  // return isLogin ? (
  //   <main>
  //     <AppSidebar />
  //     <div className="wrapper d-flex flex-column min-vh-100 bg-light">
  //       <AppHeader />
  //       <div className="body flex-grow-1 px-3">
  //         <React.Suspense fallback={<Loading />}>
  //           <CContainer fluid>
  //             <Outlet />
  //           </CContainer>
  //         </React.Suspense>
  //       </div>
  //     </div>
  //   </main>
  // ) : (
  //   <Navigate to={routesMap.LOGIN} replace />
  // );
};

export default PrivateLayout;
