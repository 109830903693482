import { store } from 'app/store';
import ErrorBoundary from 'components/ErrorBoundary';
import Loading from 'components/Loading';
import vi from 'date-fns/locale/vi';
import AppContainer from 'layouts/AppContainer';
import React, { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from 'configs/firebase';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

registerLocale('vi', vi);
setDefaultLocale('vi');
const App: React.FC = () => {

  useEffect(() => {
    // Initialize Firebase
    initializeApp(firebaseConfig)
  },[])

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <React.Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<AppContainer />} />
            </Routes>
          </BrowserRouter>
        </React.Suspense>
      </Provider>
      <ToastContainer autoClose={3000} />
    </ErrorBoundary>
  );
};

export default App;
