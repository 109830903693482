export const HOME = '/'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const FORGET_PASSWORD = '/forget-password'

const routesMap = {
  HOME,
  LOGIN,
  SIGNUP,
  FORGET_PASSWORD,
};

export default routesMap;
