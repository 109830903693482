/* eslint-disable @typescript-eslint/ban-types*/
/* eslint-disable no-extra-boolean-cast*/
/* eslint-disable @typescript-eslint/no-explicit-any*/
import { find, get, replace, trim } from 'lodash';
import { NumberFormatValues } from 'react-number-format';
import * as XLSX from 'xlsx';
import { WorkSheet } from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import * as yup from 'yup';
import { apiApp } from 'api/request';
import urlApp from 'api/urlApp';
import routesMap from 'layouts/routesMap';
import Cookies from 'js-cookie'
import { MESSAGE_ERR, REACT_DEFAULT_RETURN_URL, regexOnlyNumber } from './constants';
import parse_query_string from './parse_query_string';
import { COOKIE_CODE, setStorageData, STORAGE_KEY, timeExpCookie } from './storage';
// import { COOKIE_CODE, getStorageData, setStorageData, STORAGE_KEY } from './storage';
// import { toastError } from './toastify';


export const pageItemsDefault = '10';

/**
 * @param error object error
 * @param path get error for path, default: message
 */
export const getMessageError = (error: unknown, path = 'message'): string => {
  return get(error, path, MESSAGE_ERR);
};

export const capitalizeString = (str: string): string => {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const formatterNumber = (num?: string | number): string => {
  if (!num) return '';
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberFormat = (num?: number | string): string => {
  if (!num) return '0';

  let number = 0;
  if (typeof num === 'number') {
    number = num;
  } else if (typeof num === 'string') {
    number = Number.parseFloat(num);
  } else {
    return '0';
  }

  if (Number.isNaN(number)) return '0';

  const numberFormat = new Intl.NumberFormat(); //default: English, vi-VN: Vietnamese
  return numberFormat.format(number);
};

export const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  })
}

export function handleErrorMessage(errors: yup.ValidationError[], errorName: string): string {
  return get(
    find(errors, (item: yup.ValidationError): boolean => item.path === errorName),
    'message',
    '',
  );
}

export const onChangeTextInputValue = (setValueFunction: Function) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = event.currentTarget.value;
  setValueFunction(value);
};

export const onChangeTextInputTrimValue = (setValueFunction: Function) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = trim(event.currentTarget.value);
  setValueFunction(value);
};

export const onChangeTextInputOnlyNumberValue = (setValueFunction: Function) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = trim(event.currentTarget.value);
  if(regexOnlyNumber.test(value)) {
    setValueFunction(value);
  }
};

export const onChangeTextInputValueForm = (setValueFunction: Function, callback?: Function, key?: string) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = event.currentTarget.value;
  setValueFunction(value);
  callback?.(key, value)
};

export const onChangeTextInputTrimValueForm = (setValueFunction: Function, callback?: Function, key?: string) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = trim(event.currentTarget.value);
  setValueFunction(value);
  callback?.(key, value)
};

export const onChangeTextInputOnlyNumberValueForm = (setValueFunction: Function, callback?: Function, key?: string) => (
  event: React.ChangeEvent<HTMLInputElement>,
): void => {
  const value = trim(event.currentTarget.value);
  if(regexOnlyNumber.test(value)) {
    setValueFunction(value);
    callback?.(key, value)
  }
};

export const onChangeCheckedValue = (setValueFunction: Function) => (
  event: React.FormEvent<HTMLInputElement>,
): void => {
  const checked = event.currentTarget.checked;
  setValueFunction(checked);
};


export const onChangeTextInputNumberFormat = (setValueFunction: Function) => (values: NumberFormatValues): void => {
  setValueFunction(values.floatValue || '');
};

export const cleanAccents = (str: string): string => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Combining Diacritical Marks
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)

  return str;
};

export function validateXlsxNhapDon(workSheet: WorkSheet): boolean {
  return (
    get(workSheet, 'A7.v') === 'STT' &&
    get(workSheet, 'B7.v') === 'Mã đơn hàng ' &&
    get(workSheet, 'C7.v') === 'Mã KH' &&
    get(workSheet, 'D7.v') === 'Tên người gửi' &&
    get(workSheet, 'E7.v') === 'Số ĐT người gửi' &&
    get(workSheet, 'F7.v') === 'Địa chỉ gửi' &&
    get(workSheet, 'G7.v') === 'Tên người nhận (*)' &&
    get(workSheet, 'H7.v') === 'Số ĐT người nhận (*)' &&
    get(workSheet, 'I7.v') === 'Địa chỉ nhận (*)' &&
    get(workSheet, 'J7.v') === 'Tỉnh đến (*)' &&
    get(workSheet, 'K7.v') === 'Quận/Huyện đến (*)' &&
    get(workSheet, 'L7.v') === 'Phường/Xã đến (*)' &&
    get(workSheet, 'M7.v') === 'Đường/Thôn/Xóm' &&
    get(workSheet, 'N7.v') === 'Loại BK/BP (*)' &&
    // get(workSheet, 'O7.v') === 'Nhóm hàng hóa (*)' &&
    get(workSheet, 'P7.v') === 'Tên hàng hóa (*)' &&
    get(workSheet, 'Q7.v') === 'Số lượng' &&
    get(workSheet, 'R7.v') === 'Trọng lượng (gram) (*)' &&
    get(workSheet, 'S7.v') === 'Giá trị hàng (VND)' &&
    get(workSheet, 'T7.v') === 'Tiền thu hộ COD (VND)' &&
    get(workSheet, 'U7.v') === 'Phụ phí (VND)' &&
    get(workSheet, 'V7.v') === 'Điều chỉnh (VND)' &&
    get(workSheet, 'W7.v') === 'Dài (cm)' &&
    get(workSheet, 'X7.v') === 'Rộng (cm)' &&
    get(workSheet, 'Y7.v') === 'Cao (cm)' &&
    get(workSheet, 'Z7.v') === 'Dịch vụ (*)' &&
    get(workSheet, 'AA7.v') === 'Dịch vụ cộng thêm ' &&
    get(workSheet, 'AB7.v') === 'Người trả cước' &&
    get(workSheet, 'AC7.v') === 'Ghi chú' &&
    get(workSheet, 'AD7.v') === 'Thời gian giao' &&
    get(workSheet, 'AE7.v') === 'ID tỉnh' &&
    get(workSheet, 'AF7.v') === 'Mã tỉnh' &&
    get(workSheet, 'AG7.v') === 'Mã huyện' &&
    get(workSheet, 'AH7.v') === 'Mã xã'
  );
}

export function transformXlsxRowToTaoDonItem(row: any, index: number): any {
  const FWO_UUID = get(row, 'Mã đơn hàng ', '') ? get(row, 'Mã đơn hàng ', '') : uuidv4();
  const SDT: string = get(row, 'Số ĐT người nhận (*)', '');
  const FWO_ID = get(row, 'Mã đơn hàng ', '');
  const COD = get(row, 'Tiền thu hộ COD (VND)', '');
  const VALUE = get(row, 'Giá trị hàng (VND)', '0');
  const WEIGHT = get(row, 'Trọng lượng (gram) (*)', '');
  const SDT_KH: string = get(row, 'Số ĐT người gửi', '').toString();
  const address: string = get(row, 'Địa chỉ nhận (*)', '');
  let street = '';
  if (address.indexOf(',') > 0) street = address.slice(0, address.indexOf(','));
  const dichVuCongThem: string = get(row, 'Dịch vụ cộng thêm ', '');
  const listDichVuCongThem: string[] = [];
  const listDichVu: string[] = dichVuCongThem.split(',');

  if (listDichVu.length > 0 && listDichVu[0]) {
    listDichVu.forEach((el: string) => {
      const matenDichVu: string[] = el.split(' - ');
      const maDichVu = matenDichVu[0].replace('\r\n', '');
      listDichVuCongThem.push(maDichVu);
    });
  }
  const service_des = get(row, 'Dịch vụ (*)', ''),
    arrSrvDes = service_des.split('-'),
    service_code = arrSrvDes[0].toString();
  const service = {
    SERVICE_TYPE: service_code.trim(), // get(row, 'Dịch vụ (*)', ''), // chuyen sang ma
    QUANTITY: '1',
    ITEM_CAT: 'SRV',
  };

  const item = {
    Width: get(row, 'Rộng (cm)', ''),
    GOODS_VALUE: VALUE,
    GROSS_WEIGHT: WEIGHT,
    Length: get(row, 'Dài (cm)', ''),
    HEIGHT: get(row, 'Cao (cm)', ''),
    PACKAGING_MATERIAL: '',
    QUANTITY: get(row, 'Số lượng', ''),
    QUANTITY_OF_PACKAGE: get(row, 'Số lượng', ''),
    Description: get(row, 'Tên hàng hóa (*)', ''),
    Dimension_UoM: 'CM',
    Currency: 'VND',
    Weight_UoM: 'G',
    Flag: '',
    COD: COD,
    NET_WEIGHT: '',
    NHOM_HANG_HOA: get(row, 'Loại BK/BP (*)', ''), // đổi tên col từ nhóm thành loại bk/bp
    LOAI_HANG_HOA: get(row, 'Nhóm hàng hóa (*)', ''), // đổi tên col từ loại thành nhóm
    ITEM_CAT: 'PKG',
  };

  const listDonHang: any[] = [];
  listDonHang.push(item);
  listDonHang.push(service);

  for (let index = 0; index < listDichVuCongThem.length; index++) {
    listDonHang.push({
      SERVICE_TYPE: listDichVuCongThem[index], // chuyen sang ma
      QUANTITY: '1',
      ITEM_CAT: 'SRV',
    });
  }
  const SoTT = index + 1;
  return {
    STT: SoTT.toString(),
    ID: get(row, 'Id', ''),
    FWO: FWO_UUID.toString(),
    FWO_NON_UUID: FWO_ID.toString(),
    ADDRESS_CONSIG: get(row, 'Địa chỉ nhận (*)', ''),
    ADDRESS_OP: get(row, 'Địa chỉ gửi', ''),
    ADDRESS_SHIPPER: get(row, 'Địa chỉ gửi', ''),
    BUYERS_REFERENCE_NUMBER: '',
    CAMPAIGN: '',
    CITY_DES: get(row, 'ID tỉnh', ''),
    CITY_DES_NAME: get(row, 'Tỉnh đến (*)', ''),
    CITY_SRC: get(row, 'CITY_SRC', ''),
    SHIPPER: get(row, 'Mã KH', '') === '' ? '9999999999' : get(row, 'Mã KH', ''),
    COUNTRY_DES: 'VN',
    COUNTRY_SRC: get(row, 'COUNTRY_SRC', ''),
    CUS_ID: '',
    DISTRICT_DES: get(row, 'Mã huyện', ''),
    DISTRICT_DES_NAME: get(row, 'Quận/Huyện đến (*)', ''),
    DISTRICT_SRC: get(row, 'DISTRICT_SRC', ''),
    EMAIL_CONSIG: '',
    STREET_NAME_CONSIG: get(row, 'Đường/Thôn/Xóm', ''),
    EMAIL_OP: '',
    EMAIL_SHIPPER: '',
    FREIGH_TERM:
      get(row, 'Người trả cước', '') === '' ? '' : get(row, 'Người trả cước', '') === 'Người gửi trả' ? 'F1' : 'F2',
    HOUSE_ID_SRC: '',
    HOUSE_ID_DES: '',
    SURCHARGE: get(row, 'Phụ phí (VND)', ''),
    ITEM: listDonHang,
    LOCATION_ID_SRC: '',
    ADJUSTMENT_CHARGE: get(row, 'Điều chỉnh (VND)', ''),
    CHARGE_ADJUSTMENT: get(row, 'Điều chỉnh (VND)', ''),
    MOVEMENT_TYPE:
      get(row, 'Địa điểm giao nhận hàng', 'Giao nhận hàng tại nhà') === 'Giao nhận hàng tại nhà' ? 'ZPD' : 'ZPD',
    NAME_CONSIG: get(row, 'Tên người nhận (*)', ''),
    NAME_OP: get(row, 'Tên người gửi', ''),
    NAME_SHIPPER: get(row, 'Tên người gửi', ''),
    NOTE: get(row, 'Ghi chú', ''),
    OLD_CAMPAIGN_ID: 0,
    ORDERING_PARTY: get(row, 'Mã KH', '') === '' ? '9999999999' : get(row, 'Mã KH', ''),
    ORDER_TYPE: 'V001',
    PHONE_CONSIG: SDT.split(' ').join(''),
    PHONE_OP: SDT_KH.split(' ').join(''),
    PHONE_SHIPPER: SDT_KH.split(' ').join(''),
    POSTAL_CODE_DES: '',
    POSTAL_CODE_SRC: '',
    REQUEST_PICK_DATE: null,
    REQUEST_DELIV_DATE: get(row, 'Thời gian giao', 'Cả ngày'),
    SALE_OFFICE: get(row, 'POSTOFFICE', ''),
    SOURCE_TYPE: '03',
    COMMODITY_CODE_NAME: get(row, 'id', ''),
    COMMODITY_TYPE_NAME: get(row, 'id', ''),
    STREET_NAME_DES: street,
    STREET_NAME_SRC: get(row, 'STREET_SRC', ''),
    TEL_DES: SDT.split(' ').join(''),
    TEL_SRC: SDT_KH.split(' ').join(''),
    TRANSPORTATION_MODE: '01',
    WARD_DES: get(row, 'Mã xã', ''),
    WARD_DES_NAME: get(row, 'Phường/Xã đến (*)', ''),
    WARD_SRC: get(row, 'WARD_SRC', ''),
    NGUOI_TRA_CUOC: get(row, 'Người trả cước', ''),
    FILE_NAME: get(row, 'FileName', ''),
    STATUS: 'LOADING...',
    errorMes: '',
    DATE_IMPORT: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
    POSTOFFICE: get(row, 'POSTOFFICE', ''),
    BPCode: '',
    TOTAL_ORDER: 0,
    AMOUNT_ITEM: '',
    SERVICE_MAIN_DES: service_des,
    SERVICE_PLUS_DES: dichVuCongThem,
  };
}

export const ExportExcel = (data: unknown[], fileName: string, nameSheet: string) => {
  try {
    const dotFile = '.xlsx';
    if (typeof data === 'undefined' || typeof fileName === 'undefined' || typeof nameSheet === 'undefined') {
      alert('Phải nhận đủ 3 tham số: dữ liệu, tên file, tên sheet');
      return;
    }

    if (typeof data !== 'object') {
      alert('Dữ liệu đầu vào phải là một array hoặc object');
      return;
    }

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, nameSheet);

    XLSX.writeFile(wb, fileName + dotFile);
  } catch (error) {
    // alert(JSON.stringify(error.message));
  }
};

export const onDownloadTemplateFile = (url: string, fileName?: string): void => {
  try {
    const nameFile = fileName ? fileName : url.split('/').pop() || '';
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', nameFile);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export function getPageItems(): string {
  return parse_query_string('pageitems', pageItemsDefault);
}

export function changePhoneHeader(phone: string): string {
  let phoneNew = trim(phone)
  if (phone[0] === '0') {
    phoneNew = replace(phoneNew, '0', '84')
  }
  return phoneNew;
}

export function hideString(stringConvert: string, start = 3, end = 3,config = {mask: '*', length: 4} ): string {
  return (
    stringConvert.substring(0, start) +
    config.mask.repeat(config.length) +
    stringConvert.substring(stringConvert.length - end)
  );
}

export function getFullAddress(
  detailAddress: string,
  hamletName: string,
  textWard: string,
  textDistrict: string,
  textProvince: string,
): string {
  const fullAddress = `${!!detailAddress ? detailAddress + ', ' : ''}${!!hamletName ? hamletName + ', ' : ''}${!!textWard ? textWard + ', ' : ''
    }${!!textDistrict ? textDistrict + ', ' : ''}${textProvince}`;
  return fullAddress;
}

export async function logout(): Promise<void> {
  // apiApp.get<BaseResponse>(`${urlApp.logout}`)
  // if (!get(response, 'data', '')) {
  //   toastError(get(response, 'message', ''));
  //   return
  // }
  Cookies.remove(COOKIE_CODE.CODE)
  Cookies.remove(COOKIE_CODE.USER_NAME)
  await setStorageData(STORAGE_KEY.ACCESS_TOKEN, '');
  window.location.href = window.location.origin + routesMap.LOGIN + window.location.search
}

export let isLogin = false
export const isLoginCheck = async (): Promise<void> => {
  // const isLogined = Boolean(trim(getStorageData(STORAGE_KEY.ACCESS_TOKEN))) && Boolean(trim(Cookies.get(COOKIE_CODE.CODE)))
  // if (!isLogined) {
  //   const codeCookie = Cookies.get(COOKIE_CODE.CODE)
  //   if (Boolean(!!trim(codeCookie))){
  //     Cookies.remove(COOKIE_CODE.CODE)
  //   }
  //   setStorageData(STORAGE_KEY.ACCESS_TOKEN, '');
  // }
  const response = await apiApp.get<BaseResponse>(`${urlApp.getShareCode}`)
  isLogin = !!response.data
  // return !!response.data
}

export const onLoginSuccess = (code: string, phone: string, token: string) => {
  // Cookies.set(COOKIE_CODE.CODE, code, { expires: timeExpCookie })
  //   setIsLogin(true)
  Cookies.set(COOKIE_CODE.USER_NAME, phone, { expires: timeExpCookie })
  setStorageData(STORAGE_KEY.ACCESS_TOKEN, token)
  navigateUrl(code, phone)
  // navigateUrl(res.data)
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const returnUrl = urlParams.get('returnUrl');
export function navigateUrl(token: string, username: string) {
  // setChangeUrl(true)
  if (!!returnUrl) {
    window.location.href = `${decodeURIComponent(returnUrl)}?code=${token}&username=${username}`
    // window.location.href = `${decodeURIComponent(returnUrl)}?code=${token}`
  } else {
    window.location.href = `${REACT_DEFAULT_RETURN_URL}?code=${token}&username=${username}`
    // window.location.href = `${REACT_DEFAULT_RETURN_URL}?code=${token}`
  }
}