/* eslint-disable import/newline-after-import */
/* eslint-disable no-useless-escape */
import packageJson from '../../package.json';
import { REACT_APP_DEFAULT_RETURN_URL } from './env';
export const versionApp = packageJson.version;

export const REACT_DEFAULT_RETURN_URL=REACT_APP_DEFAULT_RETURN_URL;

export const MESSAGE_ERR = 'Đã có lỗi xảy ra. Vui lòng thử lại';
export const PAGE_SIZE = 10;
export const ONE_MB = 1048567; // 1 megabyte (1024 * 1024)
export const SIZE_MOBILE = 768

export const regexOnlyNumber = /^[0-9]*$/;
export const regexNonSpecial = /^[^:;.,'"<>\\/?~`+=_\-@#$%^&*(){}[\]!|]*$/;
export const regexNonSpecial2 = /^[^:;'"<>\\/?~`+=_\-@#$%^&*{}[\]|]*$/;
export const regexPhone =
  /^(1900|1800)[0-9]{4}$|(05|03|04|07|08|09|024|028)[0-9]{8}$|(\+84)[0-9]{9}$|(84)[0-9]{9}$|(\+84)[0-9]{8}$|(\+84)[0-9]{10}$|(021[012345689]|023[23456789]|020[3456789]|022[0123456789]|029[01234679]|025[123456789]|026[01239]|027[01234567])[0-9]{7}$/;
export const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;
export const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const listTabQuanLyDonhang = [
  {
    id: '1',
    label: 'Đơn không hợp lệ'
  },
  {
    id: '2',
    label: 'Đơn hợp lệ'
  },
  {
    id: '3',
    label: 'Đơn tạo thành công'
  },
  {
    id: '4',
    label: 'Đơn tạo thành công'
  },
  {
    id: '5',
    label: 'Đơn tạo thành công'
  },
  {
    id: '6',
    label: 'Đơn tạo thành công'
  },
  {
    id: '7',
    label: 'Đơn tạo thành công'
  },
  {
    id: '8',
    label: 'Đơn tạo thành công'
  },
  {
    id: '9',
    label: 'Đơn tạo thành công'
  },
  {
    id: '10',
    label: 'Đơn tạo thành công'
  },
  {
    id: '11',
    label: 'Đơn tạo thành công'
  },
  {
    id: '12',
    label: 'Đơn tạo thành công12'
  },
]

export const listTabExcel = [
  {
    id: '1',
    label: 'Đơn không hợp lệ'
  },
  {
    id: '2',
    label: 'Đơn hợp lệ'
  },
  {
    id: '3',
    label: 'Đơn tạo thành công'
  },
]

export const listStatusDiaChi = [
  {
    id: '',
    label: 'Tất cả'
  },
  {
    id: '1',
    label: 'Hoạt động'
  },
  {
    id: '2',
    label: 'Ngừng hoạt động'
  },
]

export const listTimeGiaoHang: TypeaheadOption[] = [
  {
    id: 'ca_ngay',
    label: 'Cả ngày',
  },
  {
    id: 'sang',
    label: 'Sáng (7h30 - 12h00)',
  },
  {
    id: 'chieu',
    label: 'Chiều (13h30 - 18h00)',
  },
  {
    id: 'toi',
    label: 'Tối (18h30 - 21h00)',
  },
  {
    id: 'gio_hanhchinh',
    label: 'Giờ hành chính (7h30 - 11h30; 13h30 ...',
  },
  {
    id: 'chu_nhat',
    label: 'Chủ nhật',
  },
  {
    id: 'ngay_le',
    label: 'Ngày nghỉ lễ',
  },
];

export const TypeaheadConfig = {
  emptyLabel: 'Không có dữ liệu',
  paginationText: 'Hiển thị thêm kết quả',
};

export const ListLoaiKhieuNai = [
  {
    "id": "46",
    "label": "Khiếu nại khâu phát bưu gửi",
  },
  {
    "id": "2",
    "label": "Khiếu nại nhận bưu gửi",
  },
  {
    "id": "57",
    "label": "Khiếu nại về thái độ phục vụ",
  },
  {
    "id": "78",
    "label": "Khiếu nại việc mất/ hư hỏng/ thiếu bưu gửi",
  },
  {
    "id": "70",
    "label": "Khiếu nại về việc thanh toán chậm muộn, sai cước",
  },
  {
    "id": "89",
    "label": "Yêu cầu khác",
  },
  {
    "id": "90",
    "label": "Hỗ trợ phát tiếp",
  },
  {
    "id": "92",
    "label": "Hỗ trợ chuyển hoàn",
  },
  {
    "id": "91",
    "label": "Hỗ trợ duyệt hoàn",
  },
  {
    "id": "93",
    "label": "Hỗ trợ sửa COD",
  }
];